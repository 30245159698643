import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import RichText from '~components/RichText'
import Seo from '~components/Seo'
import DataViewer from '~utils/DataViewer'
import { mobile } from '~styles/global'
import Section from '~components/Section'
import Slider from '~components/Slider'
import {	Header, Heading, Color, Subtitle, StyledSlider, Left, Right, Next, NextRow, NextLink, NextText, RightArrow } from '~styles/gradProdStyles'
import Image from '~components/Image'
import { css, Global } from '@emotion/react'
import resolveLink from '~utils/resolveLink'

const Graduate = ({ data }) => {
	const { page } = data

	let graduates = data?.allSanityGraduate?.nodes
	graduates = graduates?.filter(g => (
		g?.course[0]?.title === page?.course[0]?.title
	))

	const currentIndex = graduates?.findIndex(p => p?.slug?.current === page?.slug?.current)
	const nextGraduate = currentIndex < (graduates?.length - 1) ? graduates[currentIndex + 1] : graduates[0]

	return(
		<>
			<Global 
				styles={css`
					:root{
						--featureColor: ${page?.course[0]?.keyColour};
					}
				`}
			/>
			<Seo 
				title={page.title}
				metaTitle={page.seo?.metaTitle}
				description={page.seo?.metaDescription}
				image={page.seo?.socialImage}
			/>
			<DataViewer data={page} name="page"/>
			<Header>
				<Heading>
					<Color color={page.course[0]?.keyColour}>
						{page.title} {page.pronouns}
					</Color>
					<Subtitle>
						{page.description}
					</Subtitle>
				</Heading>
			</Header>
			<StyledSlider slides={page.work}/>
			{page.showProductionFields && 
				<Productions>
					<ProductionTitle>{page.productionTitle}</ProductionTitle>
					<ProductionText><RichText content={page.productionText}/></ProductionText>
				</Productions>			
			}
			<Content>
				{page.showProductionFields && <Title>About</Title>}
				<Left className='h4'>
					<DetailsMob>
						{page.website && <Detail>Website: <a href={page.website} target='_blank' rel='noreferrer'>{(page.website).replace('https://','')}</a></Detail>}
						{page.enquiries && <Detail>Enquiries: <a href={`mailto:${page.enquiries}`}>{page.enquiries}</a></Detail>}
					</DetailsMob>
					<Headshot image={page.headshot}/>	
					<DetailsDesk>
						{page.website && <Detail>Website: <a href={page.website} target='_blank' rel='noreferrer'>{(page.website).replace('https://','')}</a></Detail>}
						{page.enquiries && <Detail>Enquiries: <a href={`mailto:${page.enquiries}`}>{page.enquiries}</a></Detail>}
					</DetailsDesk>
					<RichText content={page.credits}/>
				</Left>
				<Right>
					<RichText content={page.aboutText}/>
				</Right>
			</Content>
			{nextGraduate &&
				<Next>
					<NextRow className='h1'>
						<NextLink to={resolveLink(nextGraduate)} >
							<NextText>Next Graduate</NextText><RightArrow />
						</NextLink>
					</NextRow>
				</Next>
			}
		</>
	)
}

const Content = styled(Section)`
	margin-bottom: 240px;
	${mobile}{
		margin-bottom: 118px;
	}
`
const Headshot = styled(Image)`
	width: 378px;
	margin-bottom: 20px;
	${mobile}{
		width: 50%;
	}
`
const DetailsDesk = styled.div`
	${mobile}{
		display: none;
	}
`
const DetailsMob = styled.div`
	display: none;
	${mobile}{
		display: block;
		margin-bottom: 40px;
	}
`
const Title = styled.h1`
	color: var(--featureColor);
	margin-bottom: 63px;
	grid-row: 1;
	${mobile}{
		margin-bottom: 28px;
	}
`
const Detail = styled.div`
	
`
const Productions = styled(Section)`
	margin-bottom: 200px;
	${mobile}{
		margin-bottom: 68px;
	}
`
const ProductionTitle = styled(Title)`
	grid-column: span 12;
`
const ProductionText = styled.div`
	grid-column: span 6;
	${mobile}{
		grid-column: span 12;
	}
`

Graduate.propTypes = {
	data: PropTypes.object,
}

export const query = graphql`
  query GraduateQuery($slug: String) {
    page: sanityGraduate(slug: {current: {eq: $slug}}) {
			title
			slug{
				current
			}
			description
			pronouns
			course {
				title
				keyColour
			}
			thumbnailImage{
				...imageWithAlt
			}
			headshot {
				...imageWithAlt
			}
			work {
				... on SanityImageWithCaption {
					_key
					_type
					...imageWithCaption
					asset {
						metadata {
							dimensions {
								aspectRatio
								width
								height
							}
						}
					}
				}
				... on SanityVideoWithCaption {
					_key
					_type
					...videoWithCaption
				}
			}
			showProductionFields
			productionTitle
			productionText: _rawProductionText(resolveReferences: {maxDepth: 8})
			productionLink{
				...link
			}
			website
			enquiries
			aboutText: _rawAboutText(resolveReferences: {maxDepth: 8})
    }
		allSanityGraduate {
			nodes {
				_type
				slug{
					current
				}
				course {
					title
				}
			}
		}
  }
`

export default Graduate